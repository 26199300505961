<template>
  <div class="library">
    <div class="title">
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="订单号" prop="orderNo">
          <el-input
            placeholder="请输入订单号"
            v-model="searchForm.orderNo"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="原设备" prop="oldCommunicationBoardNo">
          <el-input
            placeholder="请输入原通讯板编号"
            v-model="searchForm.oldCommunicationBoardNo"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="新设备" prop="newCommunicationBoardNo">
          <el-input
            placeholder="请输入新通讯板编号"
            v-model="searchForm.newCommunicationBoardNo"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-table :data="list" style="width: 100%">
        <el-table-column prop="orderNo" label="订单号"> </el-table-column>
        <el-table-column prop="oldCommunicationBoardNo" label="原设备通讯板号">
        </el-table-column>
        <el-table-column prop="newCommunicationBoardNo" label="新设备通讯板号">
        </el-table-column>
        <el-table-column prop="createTime" label="更换时间"> </el-table-column>
      </el-table>
    </div>
    <div class="fr mt20">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="beginIndex"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 50, 100, 150, 200]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { queryElectricityReplaceRecordList } from "@/api/order";
import { DEAL_STATUS, PAY_TYPE } from "@/js/constant";

export default {
  data() {
    return {
      DEAL_STATUS,
      PAY_TYPE,
      searchForm: {
        orderNo: "",
        oldCommunicationBoardNo: "",
        newCommunicationBoardNo: ""
      },
      beginIndex: 1,
      pageSize: 20,
      list: [],
      total: 0
    };
  },
  beforeDestroy() {
    document.onkeydown = null
  },
  mounted() {
    const _this = this;
    document.onkeydown = function(e) {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.onSearch()
      }
    };
    this.queryElectricityReplaceRecordList();
  },
  methods: {
    onSearch() {
      this.beginIndex = 1;
      this.queryElectricityReplaceRecordList();
    },
    async queryElectricityReplaceRecordList() {
      let page = this.beginIndex;
      page = page - 1;
      const params = {
        ...this.searchForm,
        beginIndex: page,
        pageSize: this.pageSize
      };
      const res = await queryElectricityReplaceRecordList(params);
      this.list = res.ldata;
      this.total = res.mdata.total || 0;
    },
    /** 分页 */
    handleCurrentChange(page) {
      this.beginIndex = page;
      this.queryElectricityReplaceRecordList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.onSearch();
    }
  }
};
</script>

<style scoped>
.library {
  padding-bottom: 80px;
}
</style>
